import React from "react";

function Resume() {
    return (
        <div>
            <h1>Resume</h1>
        </div>
    )
}

export default Resume;